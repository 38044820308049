var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('IbTabs'),_c('b-overlay',{attrs:{"show":_vm.show,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-card',[_c('b-row',_vm._l((Object.keys(_vm.dashboard)),function(key){return _c('b-col',{key:_vm.dashboard[key].id,attrs:{"xl":"3","lg":"6","sm":"12"}},[_c('statisticCard',{attrs:{"client":_vm.dashboard[key],"show":false}})],1)}),1)],1),_c('b-card',[_c('div',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(function (e){ return _vm.callGetChartDataFunction(e,'commissions'); }),expression:"(e)=>callGetChartDataFunction(e,'commissions')",modifiers:{"once":true}}],staticClass:"d-flex",staticStyle:{"gap":"16px"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('commissions')))]),(_vm.chartData.commissions.loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e()],1),_c('b-row',[_c('b-col',[_c('b-row',{staticClass:"m-0"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-1'),expression:"'modal-1'"}],staticClass:"durationBtn",attrs:{"size":"sm"},on:{"click":function (){
                _vm.durationChartName='commissions'
              }}},[_vm._v(" "+_vm._s(_vm.$t('date'))+" ")])],1),_c('LineChart',{ref:"lineChart",staticClass:"mb-5",attrs:{"chart-data":_vm.chartData.commissions,"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  ticks: {
                    precision: 0,
                    maxRotation: 90,
                  },
                  suggestedMin: 0,
                  suggestedMax: Math.max.apply(Math, [].concat( _vm.chartData.new_users.datasets[0].data )) + 10,
                },
                x:{
                  ticks:{
                    font:{
                      family:'pelak'
                    }
                  }
                }
              },
              interaction: {
                intersect: false,
              },
              plugins: {
                legend:{
                  labels: {
                    font: {
                      family: 'pelak'
                    }
                  }
                }
              }
            }}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-card',{staticStyle:{"height":"calc(100% - 32px)"}},[_c('div',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(function (e){ return _vm.callGetChartDataFunction(e,'active_users'); }),expression:"(e)=>callGetChartDataFunction(e,'active_users')",modifiers:{"once":true}}],staticClass:"d-flex",staticStyle:{"gap":"16px"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('active_users')))]),(_vm.chartData.active_users.loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e()],1),_c('DoughnutChart',{ref:"doughnutChart",attrs:{"chart-data":_vm.chartData.active_users,"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function label(context) {
                      var label = context.label || ''
                      return [((label.user.first_name) + " " + (label.user.last_name)), label.user.email, ("trade: " + (_vm.toFixed(label.traded,2)) + " lots")]
                    },
                  },
                },
                legend: {
                  display: true,
                  labels: {
                    font: {
                      family:'Pelak'
                    },
                    generateLabels: function generateLabels(chart) {
                      return chart.data.labels.map(function (label, index) { return ({
                        text: ((label.user.first_name) + " " + (label.user.last_name)),
                        fillStyle: chart.data.datasets[0].backgroundColor[index],
                      }); })
                    },
                  },
                },
              },
            }}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-card',{staticStyle:{"height":"calc(100% - 32px)"}},[_c('div',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(function (e){ return _vm.callGetChartDataFunction(e,'trades'); }),expression:"(e)=>callGetChartDataFunction(e,'trades')",modifiers:{"once":true}}],staticClass:"d-flex",staticStyle:{"gap":"16px"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('trades_per_token')))]),(_vm.chartData.trades.loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e()],1),_c('DoughnutChart',{ref:"doughnutCharttrades",attrs:{"chart-data":_vm.chartData.trades,"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    font: {
                      family:'Pelak'
                    },
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function label(context) {
                      var label = context.label || ''
                      return [ (label + ": " + (_vm.toFixed(context.formattedValue,2)) + " lots")]
                    },
                  },
                },
              }
            }}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('b-card',{staticStyle:{"height":"calc(100% - 32px)"}},[_c('div',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(function (e){ return _vm.callGetChartDataFunction(e,'new_users'); }),expression:"(e)=>callGetChartDataFunction(e,'new_users')",modifiers:{"once":true}}],staticClass:"d-flex",staticStyle:{"gap":"16px"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('new_users')))]),(_vm.chartData.new_users.loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e()],1),_c('b-row',{staticClass:"m-0"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-1'),expression:"'modal-1'"}],staticClass:"durationBtn",attrs:{"size":"sm"},on:{"click":function (){
                _vm.durationChartName='new_users'
              }}},[_vm._v(" "+_vm._s(_vm.$t('date'))+" ")])],1),_c('LineChart',{ref:"lineChart",attrs:{"chart-data":_vm.chartData.new_users,"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  ticks: {
                    precision: 0,
                    maxRotation: 90,
                  },
                  suggestedMin: 0,
                  suggestedMax: Math.max.apply(Math, [].concat( _vm.chartData.new_users.datasets[0].data )) + 3,
                },
                x:{
                  ticks:{
                    font:{
                      family:'pelak'
                    }
                  }
                }
              },
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    font: {
                      family:'Pelak'
                    },
                  },
                },
              },
              interaction: {
                intersect: false,
              },
            }}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-card',{staticStyle:{"height":"100%"}},[_c('div',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(function (e){ return _vm.callGetChartDataFunction(e,'equity_balance'); }),expression:"(e)=>callGetChartDataFunction(e,'equity_balance')",modifiers:{"once":true}}],staticClass:"d-flex",staticStyle:{"gap":"16px"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('equity_vs_balance')))]),(_vm.chartData.equity_balance.loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e()],1),_c('b-row',{staticClass:"m-0"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-1'),expression:"'modal-1'"}],staticClass:"durationBtn",attrs:{"size":"sm"},on:{"click":function (){
                _vm.durationChartName='equity_balance'
              }}},[_vm._v(" "+_vm._s(_vm.$t('date'))+" ")])],1),_c('LineChart',{ref:"lineChart",attrs:{"chart-data":_vm.chartData.equity_balance,"chart-options":{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                y: {
                  ticks: {
                    precision: 0,
                    maxRotation: 90,
                  },
                  suggestedMin: 0,
                  suggestedMax: Math.max.apply(Math, [].concat( _vm.chartData.new_users.datasets[0].data )) + 10,
                },
                x:{
                  ticks:{
                    font:{
                      family:'pelak'
                    }
                  }
                }
              },
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    font: {
                      family:'Pelak'
                    },
                  },
                },
              },
              interaction: {
                intersect: false,
              },
            }}})],1)],1),_c('b-col',[_c('b-card',{staticStyle:{"height":"100%"}},[_c('div',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(function (e){ return _vm.callGetChartDataFunction(e,'deposit_withdrawal'); }),expression:"(e)=>callGetChartDataFunction(e,'deposit_withdrawal')",modifiers:{"once":true}}],staticClass:"d-flex",staticStyle:{"gap":"16px"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('deposit_vs_withdraw')))]),(_vm.chartData.deposit_withdrawal.loading)?_c('b-spinner',{attrs:{"label":"Spinning"}}):_vm._e()],1),_c('b-row',{staticClass:"m-0"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('modal-1'),expression:"'modal-1'"}],staticClass:"durationBtn",attrs:{"size":"sm"},on:{"click":function (){
                _vm.durationChartName='deposit_withdrawal'
              }}},[_vm._v(" "+_vm._s(_vm.$t('date'))+" ")])],1),_c('BarChart',{ref:"barChartDW",attrs:{"chart-data":_vm.chartData.deposit_withdrawal}})],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"modal-1","hide-footer":""},on:{"hidden":function (){
      _vm.durationDate=''
    }}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onDurationChange( _vm.durationBtnSelected,_vm.durationChartName,_vm.durationDate)}}},[_c('b-row',{staticStyle:{"gap":"5px"}},_vm._l((_vm.durations),function(duration){return _c('b-button',{key:duration + 'durationbtneKey',class:duration === _vm.durationBtnSelected ? 'durationBtnUnselected' : 'durationBtn',attrs:{"size":"sm"},on:{"click":(function (){
            _vm.durationBtnSelected = duration
          })}},[_vm._v(" "+_vm._s(_vm.$t(duration))+" ")])}),1),_c('b-form-group',{attrs:{"label":_vm.$t('date'),"label-for":"date"}},[_c('date-picker',{attrs:{"id":"date","type":_vm.durationBtnSelected=='monthly'?'year':(_vm.durationBtnSelected=='weekly'?'year-month':'year'),"locale":"en","format":_vm.durationBtnSelected=='monthly'?'YYYY':'YYYY-MM',"max":(new Date()),"auto-submit":"","clearable":"","disabled":_vm.durationBtnSelected=='yearly'},on:{"year-change":_vm.onYearChange},model:{value:(_vm.durationDate),callback:function ($$v) {_vm.durationDate=$$v},expression:"durationDate"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }